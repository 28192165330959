import React from "react";
import { graphql } from "gatsby";
import MainWrap from "../components/MainWrap";
import LatestNews from "../components/LatestNews";

import YearsInBusiness from "../components/YearsInBusiness";

import bijan from "./images/bijan_moazami.png";
import years_50 from "./images/50_years_award.jpg";
import master_pilot from "./images/master_pilot_award.jpg";

const settings = {
  name: "Home",
  msg: "Welcome to Bijan Air!",
  img1: "sunset.jpg",
  img2: "hangar.jpg",
  subhead: "Welcome",
  subheadArrow: "Latest News",
  subheadIndex: 1,
  sideImage: "vert.jpg",
  title: "Welcome to Bijan Air, Inc.",
  heading: "Home"
};

const HomePage = ({ location, data }) => {
  const Subheader = props => (
    <LatestNews {...props} data={data.allMarkdownRemark.edges} />
  );
  return (
    <MainWrap Subheader={Subheader} settings={settings} location={location}>
      <h2>Welcome to Bijan Air!</h2>
      <p>
        Established in 1988, <em>Bijan Air, Inc.</em> provides professional
        helicopter services to the public.
      </p>
      <p className="center">
        <img src={bijan} alt="Bijan Moazami celebrates 50 years of flying" />
        <br />
        Celebrating <em>50</em> Years of flawless flying for Bijan Moazami
        (1968-2018)
      </p>
      <p className="center">
        <img src={years_50} alt="50 years of flying" />
        <img src={master_pilot} alt="Bijan receives master of flying" />
      </p>
      <p>
        For over <YearsInBusiness /> years Bijan Air has been a leader in safe
        aircraft services such as:
      </p>
      <img
        style={{ float: "right" }}
        src="/images/bijan_air_celebrates_30_years.png"
        alt="Bijan Air celebrates 30 years of safe aircraft services"
      />
      <ul>
        <li>helicopter charters</li>
        <li>maintenance</li>
        <li>specialized flight training</li>
        <li>aerial photography</li>
        <li>aerial motion pictures</li>
        <li>power &amp; pipeline patrol</li>
        <li>ENG operations</li>
      </ul>
      <p>
        As the authorized{" "}
        <a
          href="http://www.sacusa.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schweizer Helicopter
        </a>{" "}
        sales and service center for Michigan, Indiana, and Ohio we provide the
        products and solutions you can count on for all your rotorcraft needs.
      </p>
    </MainWrap>
  );
};

export default HomePage;

export const query = graphql`
  query HomePageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(news)/[^/]+[.]md$/" } }
      limit: 1
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            intro
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
